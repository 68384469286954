import React, { useRef } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

const StyledAboutSection = styled.section`
  @media (max-width: 480px) {
    padding: 121px 0px;
  }
  @media (max-width: 768px) {
    padding: 121px 0px;
  }

  .inner {
    display: grid;
    // grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;

const PrivacyPolicy = () => {
  const revealContainer = useRef(null);

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h1>Privacy Policy</h1>

      <div className="inner">
        <StyledText>
          <div>
            <h4>Overview</h4>
            <p>
              We value the trust you place in us. That's why we insist upon the highest standards
              for secure transactions and customer information privacy. Please read the following
              statement to learn about what information we gather and how we use it.
            </p>
            <p>
              {`For the purpose of this Privacy Policy, wherever the context so requires "You" shall
              mean anyone using the website. The term "We", "us",  shall mean STOCKIZEN RESEARCH`}
            </p>

            <p>
              Note: We might have to update our privacy policy in the future so to make sure you are
              aware of any changes, please review this policy periodically.
            </p>

            <p>
              By visiting the website you agree to the terms and conditions specified in this
              Privacy Policy. If you do not agree you can opt to not use or access the website and
              contact us with any questions. We will be happy to address your concerns.
            </p>

            <p>
              By mere use of the website, you expressly consent to our use and appropriate
              disclosure of your personal information in accordance with this Privacy Policy.
            </p>
            <br></br>

            <h5>
              The provided text is a disclaimer and user agreement from STOCKIZEN RESEARCH, a SEBI
              registered Research Entity. Here is a summary of the key points:
            </h5>

            <ol>
              {[
                `<b>SEBI Registration</b>: STOCKIZEN RESEARCH is registered with SEBI (Securities and Exchange Board of India) as a Research Entity.`,

                `<b>Nature of Business:</b> The company provides fundamental and technical reports, including charts and tools, to identify market patterns for investors.`,

                `<b>Disclaimer on Information Accuracy:</b> The information and views in the reports are believed to be reliable, but the company does not guarantee their accuracy, completeness, or reliability. Investors are advised to independently evaluate market conditions and risks.`,

                `<b>Confidentiality:</b> The reports are confidential and intended solely for the selected recipient. Unauthorized distribution or alteration is prohibited without prior written consent.`,

                `<b>Data Source:</b> The information in the reports is derived from publicly available data, and the company does not guarantee the accuracy or completeness of the data.`,

                `<b>Risk Disclaimer:</b> The disclaimer emphasizes that investment in securities is subject to market risks, and past performance is not indicative of future results.`,

                `<b>Independence and Conflict of Interest:</b> The company strives to minimize conflicts of interest in preparing research reports. It discloses that it or its partners may have financial interests but did not receive compensation from the subject companies mentioned in the report.`,

                `<b>Ownership Disclosure:</b> The company and its partners do not collectively own 1% or more of the equity securities of the subject companies mentioned in the report.`,

                `<b>Disciplinary Action:</b> The document confirms that no material disciplinary action has been taken against the company by any regulatory authority impacting equity research activities.`,

                `<b>Website Disclaimer:</b> The website (https://www.stockizenresearch.com) is not responsible for errors, omissions, or representations on its pages. It disclaims liability for risks associated with internet and SMS-based information dissemination.`,

                `<b>Warranty Disclaimer:</b> The website expressly disclaims any implied warranties and considers itself subject to the jurisdiction of the court of Gujarat in India.`,

                `<b>Geographical Limitation:</b> The website is specifically for users in the territory of India, and while access for users outside India is not denied, the website disclaims legal liabilities in jurisdictions other than India.`,

                `<b>User Authorization:</b> By using the website, users authorize STOCKIZEN RESEARCH to contact them and send promotional and transactional communication.`,

                `<b>Termination of Accounts:</b> The company reserves the right to terminate accounts of subscribers/customers violating proprietary rights.`,

                `<b>Communication Authorization:</b> Users authorize the company to contact them, even if registered under the National Do Not Call Registry or other related regulations.`,
                `<b>Cookies:</b> A "cookie" is a small piece of information stored by a web server on a web browser so it can be later read back from that browser. Cookies are useful for enabling the browser to remember information specific to a given user. We place both permanent and temporary cookies in your computer's hard drive. The cookies do not contain any of your personally identifiable information.`,
                `<b>Links to Other Sites:</b> Our website may contain links to other external websites that may collect personally identifiable information about you. https://stockizenresearch.com is not responsible for the privacy practices or the content of those linked Services.`,
                `<b>Your Consent:</b> By using the website and/ or by providing your information, you consent to the collection and use of the information you disclose on the website in accordance with this Privacy Policy, including but not limited to Your consent for sharing your information as per this privacy policy.
                <br><br>
If we decide to change our privacy policy, we will post those changes on this page so that you are always aware of what information we collect, how we use it, and under what circumstances we disclose it.`,
              ].map((item, index) => (
                <li
                  key={index}
                  style={{
                    fontSize: '16px',
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                  }}>
                  {parse(item)}
                </li>
              ))}
            </ol>
            <h5>
              It is crucial for users and investors to carefully read and understand these
              disclaimers and agreements before using the services or relying on the provided
              information.
            </h5>
          </div>
        </StyledText>
      </div>
    </StyledAboutSection>
  );
};

export default PrivacyPolicy;
