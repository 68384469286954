import React, { useRef } from 'react';
import styled from 'styled-components';

const StyledAboutSection = styled.section`
  @media (max-width: 480px) {
    padding: 121px 0px;
  }
  @media (max-width: 768px) {
    padding: 121px 0px;
  }

  .inner {
    display: grid;
    // grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;

const COC = () => {
  const revealContainer = useRef(null);

  const data = [
    {
      title: `Responsibility of management`,
      body: `The senior management of research analyst shall bear primary responsibility for ensuring the maintenance of appropriate standards of conduct and adherence to proper procedures.`,
    },
    {
      title: `Honesty and Good Faith`,
      body: `Research analyst shall act honestly and in good faith.`,
    },
    {
      title: `Compliance`,
      body: `Research analyst shall comply with all regulatory requirements applicable to the conduct of its business activities.`,
    },
    {
      title: `Conflict of Interest`,
      body: `Research analyst shall effectively address conflict of interest which may affect the impartiality of its research analysis and research report and shall make appropriate disclosures to address the same.`,
    },
    {
      title: `Diligence`,
      body: `Research analyst shall act with due skill, care and diligence and shall ensure that the research report is prepared after thorough analysis.`,
    },
    {
      title: `Insider Trading or front running`,
      body: `Research analyst or its employees shall not engage in insider trading or front running or front running of its own research report.`,
    },

    {
      title: `Confidentiality`,
      body: `Research analyst or its employees shall maintain confidentiality of report till the report is made public.`,
    },
    {
      title: `Professional Standard`,
      body: `Research analyst or its employees engaged in research analysis shall observe high professional standard while preparing research report.`,
    },
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h1>Code of Conduct</h1>

      <div className="inner">
        <StyledText>
          <div>
            {data.map((item, index) => (
              <div
                key={index}
                style={{
                  marginTop: 20,
                }}>
                <h4 style={{ margin: 0 }}>{item.title}</h4>
                <p
                  key={index}
                  style={{
                    fontSize: '16px',
                  }}>
                  {item.body}
                </p>
              </div>
            ))}
          </div>
        </StyledText>
      </div>
    </StyledAboutSection>
  );
};

export default COC;
