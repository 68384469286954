import React, { useRef } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

const StyledAboutSection = styled.section`
  @media (max-width: 480px) {
    padding: 121px 0px;
  }
  @media (max-width: 768px) {
    padding: 121px 0px;
  }

  .inner {
    display: grid;
    // grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;

const RefundPolicy = () => {
  const revealContainer = useRef(null);

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h1>Refund Policy</h1>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              We value our customers and are committed to providing best services. Our clients need
              to realize that we do not offer a 100% guarantee on our calls and hence cannot offer
              any refund on subscriptions regardless of the individual client's performance. Once a
              service has been subscribed to and a payment has been made for the same, it can't be
              canceled or refunded in any case. If for some unforeseen reason, the client is not
              satisfied with our services, they may call us to seek direction on future calls. We
              will give our best effort to increase the satisfaction levels in such cases. However,
              any request by the client to cancel a service or get a refund will NOT be accepted in
              any case.
            </p>
            <p>
              We strongly recommend that before making a payment, our visitors and potential
              clients, please:
            </p>

            <ol>
              {[
                `Read all information about our services and support given to our clients.`,
                `Read our Terms and Conditions.`,
                `Read our Privacy Policy and Refund Policy.`,
                `There is no refund possible in any case whatsoever.`,
              ].map((item, index) => (
                <li
                  key={index}
                  style={{
                    fontSize: '16px',
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                  }}>
                  {parse(item)}
                </li>
              ))}
            </ol>
            <p>
              Kindly make the payment after reading all terms and conditions, disclaimers and refund
              policy.
            </p>
          </div>
        </StyledText>
      </div>
    </StyledAboutSection>
  );
};

export default RefundPolicy;
