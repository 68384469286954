import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Disclaimer from './disclaimer';
import { useCookies } from 'react-cookie';

const DisclaimerPopUp = () => {
  const [open, setOpen] = useState(false);
  const [cookies, setCookie] = useCookies(['disclaimer-agree']);

  useEffect(() => {
    if (!cookies['disclaimer-agree']) {
      setOpen(true);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    setCookie('disclaimer-agree', 'true');
  };

  return (
    <Dialog fullWidth={true} maxWidth={'lg'} open={open}>
      <DialogContent>
        <Disclaimer />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DisclaimerPopUp;
